<template>
	<div class="forgot-password">
		<transition name="slide-fade">
			<div class="alert alert-success" v-if="finished">
				{{ $t('auth.afterReset') }}
			</div>
		</transition>

		<div class="card" v-if="!finished">
			<div class="content">
				<p></p>

				<div class="form-group">
					<label>{{ $t('auth.reset.email') }}</label>
					<input
						type="email"
						v-model="email"
						class="form-control"
						@keyup.enter="reset"
						autocomplete="off"
					/>
				</div>

				<div class="text-center sign-in-button">
					<button
						type="button"
						class="btn btn-fill btn-warning btn-wd"
						@click="reset">
						<template v-if="hasLoaded">{{ $t('auth.resetButton') }}</template>
						<loader v-else :inline="true"></loader>
					</button>
				</div>
			</div>
		</div>

		<div class="footer text-center">
			<router-link
				class="go-back"
				:to="{ name: 'login' }"
				v-text="$t('auth.backToLogin')"
			/>
		</div>
	</div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
	name: 'ForgotPassword',

	data: () => ({
		email: null,
		finished: false,
		hasLoaded: true
	}),

	methods: {
		...mapActions('auth', {
			resetPassword: 'resetPassword'
		}),

		async reset() {
			this.hasLoaded = false

			try {
				await this.resetPassword(this.email)
			} catch (exception) {
			}

			this.finished = true
			this.hasLoaded = true
		}
	}
}
</script>
