<template>
    <main class="auth-login">
        <!-- Invalid credentials -->
        <transition name="slide-fade">
            <div class="alert alert-primary" v-if="invalidEmail">
                <strong v-text="$t('auth.invalidEmail')"/>
                {{ $t('auth.loginWithSocial') }}
                <auth-social-login-button image="/images/login/microsoft.png"
                                          provider="live"
                                          label="Microsoft"
                />
            </div>
        </transition>

        <transition name="slide-fade">
            <div class="alert alert-danger" v-if="hasErrors && !invalidEmail">
                <span>
                    <strong v-text="$t('auth.ups')"/>
                    <strong v-if="!error"> {{ $t('auth.invalidCredentials') }} </strong>
                    <strong v-else-if="error == 404"> {{ $t('auth.error404') }} </strong>
                    <strong v-else-if="error == 500"> {{ $t('auth.error500') }} </strong>
                    {{ $t('auth.tryAgain') }}
                </span>
            </div>
        </transition>

        <form autocomplete="off" @keydown.enter="handleSubmit" @submit.prevent="handleSubmit">
            <div class="card">
                <div class="content">
                    <div class="form-group" :class="{'has-error':invalidCredentials || authenticationErrors}">
                        <input v-model="email"
                               type="email"
                               class="form-control"
                               :placeholder="$t('auth.email')"
                               autocomplete="off"
                        />
                    </div>

                    <div class="form-group password" :class="{ 'has-error': invalidCredentials || authenticationErrors}">
                        <input v-model="password"
                               :disabled="invalidEmail"
                               type="password"
                               class="form-control"
                               :placeholder="$t('auth.password')"
                               autocomplete="off"
                        />
                    </div>

                    <div class="text-center sign-in-button">
                        <button :disabled="hasErrors"
                                type="submit"
                                class="btn btn-fill btn-warning btn-block"
                        >
                            <span v-if="!isAuthenticating" v-text="$t('auth.signIn')"/>
                            <loader v-else :inline="true"/>
                        </button>
                    </div>

                    <router-link class="forgot-password"
                                 :to="{ name: 'forgot-password' }"
                                 v-text="$t('auth.forgot')"
                    />

                    <div class="hr text-center text-muted">
                        <span v-text="$t('auth.or')"/>
                    </div>

                    <div class="social-login-container">
                        <auth-social-login-button image="/images/login/microsoft.png"
                                                  provider="live"
                                                  label="Microsoft"
                        />
                        <auth-social-login-button image="/images/login/google.png"
                                                  provider="google"
                                                  label="Google"
                        />
                        <auth-social-login-button :class-list="['btn-facebook', 'btn-fill']"
                                                  :icon="['fab', 'facebook-f']"
                                                  provider="facebook"
                                                  label="Facebook"
                        />
                    </div>
                </div>
            </div>

            <div class="footer text-center">
                {{ $t('auth.noAccount') }}
                <router-link :to="{ name: 'register' }" v-text="$t('auth.signUp')"/>
            </div>
        </form>
    </main>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import AuthSocialLoginButton from '@/app/auth/components/AuthSocialLoginButton'
  import MixpanelService from '@/services/mixpanel/MixpanelService'
  import StorageService from '@/services/_app/storage/StorageService'
  import TokenService from '@/services/_app/storage/TokenService'

  export default {
    name: 'Login',
    components: { AuthSocialLoginButton },

    mounted () {
      this.checkRouteForErrors()
      this.$store.dispatch('resetState')
      this.checkForRouteRedirect()
    },

    data () {
      return {
        email: '',
        password: '',
        invalidCredentials: false,
        error: false
      }
    },

    computed: {
      ...mapGetters('auth', [
        'isAuthenticating',
        'authenticationErrors'
      ]),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),
      invalidEmail () {
        return (this.email.includes('@sgme') && this.email.includes('@generaxion') && !this.email.includes('marketing'))
      },
      hasErrors () {
        return !!(
          this.invalidEmail ||
          this.invalidCredentials ||
          this.authenticationErrors ||
          this.error
        )
      }
    },

    watch: {
      $route () {
        this.checkRouteForErrors()
      },
      email () {
        this.handleErrors()
      },
      password () {
        this.handleErrors()
      }
    },

    methods: {
      ...mapActions('auth', {
        resetErrors: 'resetErrors',
        authenticate: 'authenticate'
      }),

      async checkForRouteRedirect(){
        const redirect = this.$route.query.redirect
        if(redirect) {
          await StorageService.set('redirect', redirect)
        }
      },

      checkRouteForErrors () {
        if (this.$route.query.error) {
          this.error = this.$route.query.error
        }
      },

      handleErrors () {
        if (this.email || this.password) {
          this.invalidCredentials = false
        }
        this.error = false
        this.resetErrors()
      },

      async handleSubmit () {
        if (!this.email || !this.password) {
          this.invalidCredentials = true
          return
        }

        try {
          // Attempt to login
          const redirectTo = await this.authenticate({
            email: this.email,
            password: this.password,
            redirect: this.$route.query.redirect
          })

          MixpanelService.track('Auth - Login', { type: 'basic' })

          this.$router.push(redirectTo)
        } catch (e) {
          this.$router.push({
            name: 'login', query: {
              error: e.response.status
            }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import "~@/assets/scss/variables/all";

    .btn {
        &.btn-fill {
            border-radius:2px;
            background: $power-pink;
            transition: all 0.4s ease;

            &:hover {
                background: darken($power-pink, 10%);
                opacity: 0.85;
            }
        }
    }

    .card {
        border-radius:2px;
        margin-bottom: 22px;

        .form-group{
          .form-control{
            border-radius:2px;
          }
        }
    }

    a.forgot-password {
        cursor: pointer;
        color: #aaa;
        margin: 15px 0 15px;
        display: block;
        font-size: 13px;
        text-align: center;

        &:hover {
            color: #888;
        }
    }

    .hr {
        overflow: hidden;
        margin: 20px 0;
        font-size: 12px;

        > span {
            position: relative;
            padding: 16px;

            &::before {
                content: "" !important;
                position: absolute !important;
                border-bottom: 1px solid #e4e4e4 !important;
                top: 50% !important;
                right: 100% !important;
                width: 5000px !important;
            }

            &::after {
                content: "" !important;
                position: absolute !important;
                border-bottom: 1px solid #e4e4e4 !important;
                top: 50% !important;
                left: 100% !important;
                width: 5000px !important;
            }
        }
    }

    .footer {
        background-color: transparent;
        color: #888;

        a {
            color: #e9a838;
        }
    }

    .social-login-container div:last-child .btn {
        margin: 0;
    }
</style>
