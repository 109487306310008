<template>
    <div>
        <transition name="slide-fade">
            <div
                    class="alert alert-danger"
                    v-for="(message, index) in error"
                    :key="index"
            >
                <span>
                    <b>{{ message | capitalize }}</b>
                </span>
            </div>
        </transition>

        <template v-if="!direct || hasLoaded">
            <div class="card">
                <div class="content">
                    <div class="form-group" :class="{ 'has-error': error }">
                        <label>{{ $t('register.domain') }}</label>
                        <input
                                type="text"
                                v-model="domain"
                                placeholder="https://www ..."
                                class="form-control"
                                autocomplete="off"
                        />
                    </div>

                    <div class="form-group" :class="{ 'has-error': error }">
                        <label>{{ $t('register.email') }}</label>
                        <input
                                type="email"
                                v-model="email"
                                placeholder="E-mail ..."
                                class="form-control"
                                @keyup.enter="register"
                                autocomplete="off"
                        />
                    </div>

                    <hr/>

                    <div class="text-center sign-in-button">
                        <button
                                type="button"
                                class="btn btn-fill btn-wd btn-block lead-trigger"
                                @click="register"
                        >
                            <template v-if="hasLoaded">{{
                                $t('register.next')
                                }}
                            </template>
                            <loader v-else :inline="true"></loader>
                        </button>
                    </div>
                </div>
            </div>

            <div class="footer text-center">
                <router-link
                        class="go-back"
                        :to="{ name: 'login' }"
                        v-text="$t('auth.backToLogin')"
                />
            </div>
        </template>

        <loader v-else/>
    </div>
</template>

<style lang="scss" scoped>
    @import "~@/assets/scss/variables/all";

    .card{
      border-radius:2px;
      .content{
        .form-group{
          .form-control{
            border-radius:2px;
          }
        }
      }
    }

    .btn {
        &.btn-fill {
            border-radius:2px;
            background: $power-pink;
            transition: all 0.4s ease;

            &:hover {
                background: $power-pink-dark;
                opacity: 0.85;
            }
        }
    }


    .footer {
        background-color: transparent;

        a.go-back {
            cursor: pointer;
            color: #6d6d6d;
        }
    }
</style>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'Register',
    data () {
      return {
        domain: null,
        email: null,
        utm_source: null,

        hasLoaded: true,
        error: null,
        direct: false
      }
    },

    watch: {
      domain () {
        this.error = null
      },

      email () {
        this.error = null
      }
    },

    mounted () {
      this.loadQueryParams()
    },

    methods: {
      ...mapActions('auth', {
        getMagicLink: 'register'
      }),
      async register() {
        if (!this.hasLoaded) {
          return
        }

        this.hasLoaded = false

        const options = {
          name: this.domain,
          domain: this.domain,
          email: this.email,
          utm_source: this.utm_source
        }

        try {
          const magicLink = await this.getMagicLink(options)

          this.$router.push({
            path: '/login?code=' + magicLink
          })

        } catch (e) {
          const response = e.response

          this.hasLoaded = true
          this.direct = false

          if (response.status === 422) {
            this.error = {
              email: [$t('register.emailError')]
            }
          }
        }
      },

      loadQueryParams () {
        const currentRoute = this.$router.currentRoute

        if (!currentRoute) {
          return
        }

        const params = currentRoute.query

        this.domain = params.domain !== undefined ? params.domain : null
        this.email = params.email !== undefined ? params.email : null
        this.utm_source =
          params.utm_source !== undefined
            ? params.utm_source
            : 'website.spotonlive'

        if (this.domain && this.email) {
          this.direct = true
          this.register()
        }
      }
    }
  }
</script>
