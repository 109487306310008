<template>
    <div class="reset-password forgot-password">
        <form @submit.prevent="submit">
            <div class="card">
                <div class="content">
                    <template v-if="!success">
                        <div
                                class="alert alert-danger text-center"
                                v-show="invalidToken"
                        >
                            <strong>
                                {{ $t('auth.reset.invalidToken') }}<br/>

                                <router-link
                                        :to="{ name: 'forgot-password' }"
                                        class="text-danger"
                                >
                                    {{ $t('auth.reset.retryLink') }}
                                </router-link>
                            </strong>
                        </div>

                        <div class="form-group">
                            <label class="control-label" for="password">
                                {{ $t('auth.reset.newPassword') }}
                            </label>

                            <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    class="form-control"
                                    v-model="password"
                                    v-validate="
                                    'required|min:8|confirmed:confirmPassword'
                                "
                                    :data-vv-as="$t('auth.reset.newPassword')"
                            />
                        </div>

                        <div class="form-group">
                            <label
                                    class="control-label"
                                    for="confirmPassword"
                            >{{
                                $t('auth.reset.confirmNewPassword')
                                }}</label
                            >

                            <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    class="form-control"
                                    ref="confirmPassword"
                                    :data-vv-as="
                                    $t('auth.reset.newPasswordConfirmation')
                                "
                            />
                        </div>

                        <p v-if="veeErrors.has('password')" class="text-danger">
                            {{ veeErrors.first('password') }}
                        </p>

                        <div class="form-group">
                            <button
                                    type="submit"
                                    class="btn btn-success btn-block"
                                    :disabled="veeErrors.any()"
                            >
                                <span
                                        class="fa fa-spin fa-spinner fa-fw"
                                        v-if="submitting"
                                />&nbsp;
                                {{ $t('auth.reset.change') }}
                            </button>
                        </div>
                    </template>

                    <template v-else>
                        <div class="alert alert-success text-center">
                            <strong class="">{{ $t('auth.reset.success') }}</strong>
                        </div>

                        <hr/>

                        <div class="text-center">
                            <router-link
                                    :to="{ name: 'login' }"
                                    class="btn btn-primary btn-block"
                                    :title="$t('auth.reset.goBack')"
                            >
                                {{ $t('auth.reset.goBack') }}
                            </router-link>
                        </div>
                    </template>
                </div>
            </div>
        </form>

        <div class="footer text-center">
            <router-link
                    class="go-back"
                    :to="{ name: 'login' }"
                    v-text="$t('auth.backToLogin')"
            />
        </div>
    </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'ResetPassword',

    data () {
      return {
        password: null,
        submitting: false,
        success: false,
        invalidToken: false
      }
    },

    computed: {
      token () {
        const currentRoute = this.$router.currentRoute

        if (!currentRoute) {
          return null
        }

        return currentRoute.params.token
      }
    },

    methods: {
      ...mapActions('auth', {
        changePassword: 'changePassword'
      }),

      async submit () {
        if (this.submitting || !this.token) {
          return
        }

        const result = await this.$validator.validate()

        if (!result) {
          return
        }

        this.submitting = true

        try {
          await this.changePassword({
            token: this.token,
            password: this.password
          })

          this.submitting = false
          this.success = true
        } catch (e) {
          this.submitting = false
          this.invalidToken = true
        }
      }
    }
  }
</script>
