<template>
    <div class="text-center" @click.prevent="socialLogin">
        <button type="button" class="btn btn-block" :class="classList">
            <img v-if="image" :alt="label" :src="image" />
            <fa v-if="icon" :icon="icon" />
            {{ $t("auth.signInWith") }} {{ label }}
        </button>
    </div>
</template>

<script>
import MixpanelService from "@/services/mixpanel/MixpanelService";
import { mapActions } from "vuex";
export default {
    name: "AuthSocialLoginButton",
    props: {
        classList: {
            type: Array,
            required: false
        },
        icon: {
            type: Array,
            required: false
        },
        image: {
            type: String,
            required: false
        },
        provider: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        }
    },
    methods: {
        ...mapActions("auth", {
            getProviderUrl: "getProviderUrl"
        }),
        async socialLogin() {
            try {
                const url = await this.getProviderUrl({
                    provider: this.provider,
                    redirect: this.$route.query.redirect || null
                });

                MixpanelService.track("Auth - Login", {
                    type: "social",
                    provider: this.provider
                });

                window.location.replace(url);
            } catch (error) {
                this.$emit("error", error);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.btn {
    transition: all 0.4s ease;
    margin-bottom: 10px;
    background: white;
    border: 1px solid #cdcdcd;
    color: initial;
    border-radius:2px;

    &:hover {
        background: #fbfbfb;
    }

    img {
        width: 18px;
        margin-right: 5px;
    }

    &.btn-fill {
        color: white;
    }
    &.btn-facebook {
        border: none;
        background: #3b5998;
    }
}
</style>
